import React, {useState} from 'react'
import '../index.css'
import logo from '../signnet.png'
import myalgoLogo from '../MyAlgo.svg'
import { CloudDone } from '@pipeline-ui/icons'
import {
    Button, Modal
  } from 'pipeline-ui'
import wclogo from '../wc.svg'
import MyAlgo from '@randlabs/myalgo-connect'
import algosdk from 'algosdk'
import { useNavigate, } from "react-router-dom"
import { BallTriangle } from 'react-loading-icons'
import { formatJsonRpcRequest } from "@json-rpc-tools/utils"
// import WalletConnect from "@walletconnect/client"
// import QRCodeModal from "algorand-walletconnect-qrcode-modal"
// import { apiGetAccountAssets } from '../accountDetails/getAccountDetails'
import {PeraWalletConnect} from "@perawallet/connect"

export function WalletInfoPage() {

    const [error, setError] = useState("")
    const errorMsg = error 
  
    const [optIn, setOptInResult] = useState(false)
    const optInStatus = optIn

    const [walletAddress, setWalletAddress] = useState("")
    const address = walletAddress 
  
    const [algos, setAlgos] = useState(0)
    const algosBalance = algos

    const [asaBalance, setASABalance] = useState(0)
    const asaAmount = asaBalance
    
    const [LoadingisOpen, setLoadingIsOpen] = useState(false);
    const navigate = useNavigate()
    // async function myAlgoWallet(){

    //     try{

    //     setError("")
    //     setLoadingIsOpen(true)
    //     const myAlgoWallet = new MyAlgo()
    //     const accounts = await myAlgoWallet.connect()
    //     const addresses = accounts.map((account) => account.address)
    //     setWalletAddress(addresses[0])

    //     const accDetails = await apiGetAccountAssets(addresses[0])

    //     const algosLeft = accDetails.filter((asset) => asset.id == 0)
    //     setAlgos(algosLeft[0].amount/1000000)
        
    //     const hasOptin = accDetails.some((asset) => asset.id == process.env.REACT_APP_ASSETID)

    //     if(hasOptin){
    //         setOptInResult(true)
    //         const assetASAArray = accDetails.filter((asset) => asset.id == process.env.REACT_APP_ASSETID)

    //         const decimal = process.env.NODE_ENV == 'development' ? 100 : 10000000
    //         setASABalance(assetASAArray[0].amount/decimal)
    //     } else {
    //         setOptInResult(false)
    //         setASABalance(0)
    //     }
    //     setLoadingIsOpen(false)
    //  } catch (err) {
    //     setLoadingIsOpen(false)
    //     setError(err.message)
    //     console.log('error: ' + err)
    //   }

    // }

    async function walletConnect(){
        try{
            setError("")

            const peraWallet = new PeraWalletConnect({
                chainId: process.env.NODE_ENV === 'development' ? 416002 : 416001
            });
            peraWallet.disconnect();

            peraWallet
            .connect()
            .then(async (newAccounts) => {
              // Setup the disconnect event listener
              peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
      
              setWalletAddress(newAccounts[0]);

                const accDetails = await apiGetAccountAssets(newAccounts[0])

                const algosLeft = accDetails.filter((asset) => asset.id == 0)
                setAlgos(algosLeft[0].amount/1000000)
                
                const hasOptin = accDetails.some((asset) => asset.id == process.env.REACT_APP_ASSETID)
        
                if(hasOptin){
                    setOptInResult(true)
                    const assetASAArray = accDetails.filter((asset) => asset.id == process.env.REACT_APP_ASSETID)
        
                    const decimal = process.env.NODE_ENV == 'development' ? 100 : 10000000
                    setASABalance(assetASAArray[0].amount/decimal)
                } else {
                    setOptInResult(false)
                    setASABalance(0)
                }
                setLoadingIsOpen(false)
            })

        } catch (err) {
            setLoadingIsOpen(false)
            setError(err)
            console.log('error: ' + err)
            alert(err)
            return
      }
    }

    function handleDisconnectWalletClick() {
        peraWallet.disconnect();
        setWalletAddress(null);
     }

    async function apiGetAccountAssets(address){
        
        const baseServer = process.env.REACT_APP_ALGODCLIENT
            const port = '';
            const token = {
                'X-API-Key': ''
            }
        
        const algodClient = new algosdk.Algodv2('', baseServer, port)
              const accountInfo = await algodClient
                .accountInformation(address)
                .setIntDecoding(algosdk.IntDecoding.DEFAULT)
                .do()

        const algoBalance = accountInfo.amount
       
        const assetsFromRes = accountInfo.assets

        const assets = assetsFromRes.map(
        ({ 'asset-id': id, amount, creator, frozen }) => ({
            // id: Number(id),
            id: parseInt(id.toString()),
            amount: Number(amount),
            creator,
            frozen,
            decimals: 0,
        }),
        )
        assets.sort((a, b) => a.id - b.id)

        await Promise.all(
            assets.map(async (asset) => {
                const { params } = await algodClient.getAssetByID(asset.id).do()
                asset.name = params.name
                asset.unitName = params['unit-name']
                asset.url = params.url
                asset.decimals = params.decimals
            }),
        )

                assets.unshift({
                  id: 0,
                  amount: Number(algoBalance),
                  creator: '',
                  frozen: false,
                  decimals: 6,
                  name: 'Algo',
                  unitName: 'Algo',
                })
              
                return assets
        }

    function onProceedClick(walletAddress, algosBalance, optInStatus, asaBalance){
        const assetName =  process.env.NODE_ENV === 'development' ? 'TEST' : "SIGN"
        if(walletAddress === ''){
            setError('Please connect your wallet.')
        } else if(algosBalance < 1){
            setError('Wallet needs a minimum of 1 Algos')
        } else if(optInStatus === false){
            setError(`Not opt in to ${assetName} ASA (${process.env.REACT_APP_ASSETID})`)
        }else if(asaBalance < 25){
            setError(`Minimum of 25 ${assetName} points required.`)
        } else {
            navigate("/mint", {replace: true})
        }

    }
    
    return (
    
    <div align="center" className= 'card'>
    <img alt="Create Non-Fungible Document" src={logo} width="300"></img><br></br>
    <h2>{"Connect Wallet"}</h2>

    <div style = {{display: 'flex'}}>

        {/* <button className="AlgoButton" onClick={() => myAlgoWallet()}>
        <img src ={myalgoLogo} style = {{width: '30px', height: '30px', resizeMode: 'contain', paddingTop: '0px', paddingRight: '5px'}} />
        <a style = {{margin: 'auto', paddingRight: '1em'}} >{"MyAlgo Wallet" }</a>
        </button> */}
         
    
        <button style = {{width: '30em'}} className='wc-button' onClick={() => walletConnect()}> 
        <img src ={wclogo} style = {{width: '30px', height: '30px', resizeMode: 'contain', paddingTop: '0px', paddingRight: '5px'}} />
        <a style = {{margin: 'auto', paddingRight: '1em'}} >{"Pera Wallet" }</a> </button>
        </div>
    <h3>{"Connected Wallet Address: " + address}</h3>
    <h3>{"ALGO Balance: " + algosBalance}</h3>
    {(() => {
          const  asaName  = process.env.NODE_ENV === 'development' ? 'Test ASA' : 'SIGN'

          
            return <h3>{ "Opt-In Status " + asaName +": " + optInStatus}</h3>
          
        })()}
      {(() => {
          const  asaName  = process.env.NODE_ENV === 'development' ? 'Test ASA' : 'SIGN'

          
            return <h3>{ asaName +" Balance: " + asaAmount}</h3>
          
        })()}
        <h5 style = {{color: "red"}}>{errorMsg}</h5>
    <Button onClick={() => onProceedClick(address, algosBalance, optInStatus, asaAmount)} className="confirm-button" ><CloudDone></CloudDone><a>Proceed</a></Button>
    <Modal isOpen={LoadingisOpen}>
          <BallTriangle stroke="#06bcee" fill="transparent" speed={1.5} />
          <p style = {{color: '#06bcee'}} >Loading...</p>


        </Modal>
    </div>
    
    
    
    );
}

export default WalletInfoPage
