import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};



function AuthenticateDrop(props) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
  } = useDropzone({accept: '.json', maxFiles: 1, onDrop: (acceptedFiles) => {
    props.setFile(
      acceptedFiles[0]
    );
  }});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {borderColor: "#000"}),
  }), [
    isFocused,
  ]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="container">
         <h4>Drop Metadata.json file</h4>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drop or click here to select file</p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  );
}

export default AuthenticateDrop
