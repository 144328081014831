
import React from 'react'
import '../index.css'
import Pipeline from "@pipeline-ui-2/pipeline";
import myalgoLogo from '../MyAlgo.svg'

export const ALGOButton = (props) => {
    return (
        <div>
            <br />
            <button
                className="AlgoButton2"
                onClick={() => {
                    Pipeline.connect(props.wallet).then(accounts => {

                        if (props.returnTo !== undefined) {
                            const data = {};
                            data[props.returnTo] = accounts;
                            props.context.setState(data);
                        }
                        if (typeof props.onChange === "function") {
                            props.onChange(accounts)
                        }
                    })
                }}
            ><img src ={myalgoLogo} style = {{ width: '30px', height: '30px', resizeMode: 'contain', paddingTop: '0px', paddingRight: '5px'}} />
                <a style = {{display: 'flex', margin: 'auto', paddingRight: '1em'}} >{"MyAlgo Wallet" }</a>
            </button>
        </div>
    )
}