import React, {useState} from 'react'
import '../index.css'


export function AboutPage(props) {


    // const getIntegrity = (JsonText) => { JsonText.map()}






    return (
    
    <div> <h1> About</h1></div>
    
    );
}

export default AboutPage