import React, {useState, useRef, useLayoutEffect} from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import signIcon from '../signIcon.svg'
import { useNavigate } from "react-router-dom"



function HideOnScroll(props) {
    
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };




  
  export default function HideAppBar(props) {

    // const [anchorElNav, setAnchorElNav] = useState(null);
    // const [anchorElUser, setAnchorElUser] =useState(null);

    const nav = useRef();
    const [navHeight, setNavHeight] = useState(0);
    const [isMobile, setMobile] = useState(false);
  
    useLayoutEffect(() => {
      nav.current && setNavHeight(nav.current.clientHeight);
    }, [isMobile]);
  
    const checkIsMobile = () => (window.innerWidth < 960 ? true : false);
    if (typeof window !== 'undefined') {
      window.onresize = () => isMobile !== checkIsMobile && setMobile(checkIsMobile);
    } 

    const customTheme = createTheme({
        palette: {
          primary: {
            main: '#123456',
            contrastText: "#FFFFFF ",
          },
          secondary: {
            main: '#FFFFFF',
            contrastText: "#000000 ",
          },
 
        },
       
      });
      const navigate = useNavigate()

      

    // const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    //   };
    //   const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    //   };
    
    //   const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    //   };

    //   const handleRedirect = () => {
    //     setAnchorElUser(null);
    //   };
    //   const StyledInputBase = styled(Typography)(() => ({
    //     color: 'secondary',
    //   }));
    
    return (
        <div align="center"> 
        <CssBaseline />
        <ThemeProvider theme={customTheme}>
        <HideOnScroll {...props}>
        <AppBar color='primary' ref={nav} sx={{ display: { xs: 'flex', md: 'flex' } }} >
      
        <Toolbar >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: -17, display: { xs: 'flex', md: 'flex' } }}
          >
            <img src={signIcon}></img> 
          </Typography>

          <Box sx={{ flexGrow: 1, color: 'primary.contrastText', display: { xs: 'flex', md: 'flex' } }}>
          {/* <Button
          onClick={()=>{navigate("/about", {replace: true})}}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          About
        </Button> */}
        <Button
          onClick={()=>{navigate("/", {replace: true})}}
          sx={{ my: 2, display: { xs: 'flex', md: 'flex' }, color: 'white', display: 'block' }}
        >
          Home
        </Button>
        <Button
          onClick={()=>{navigate("/authenticate", {replace: true})}}
          sx={{ my: 2, display: { xs: 'flex', md: 'flex' }, color: 'white', display: 'block' }}
        >
          Authenticate
        </Button>
        <Button
          onClick={()=>{navigate("/help", {replace: true})}}
          sx={{ my: 2, display: { xs: 'flex', md: 'flex' }, color: 'white', display: 'block' }}
        >
          Help
        </Button>
        {/* <Button
          onClick={()=>{navigate("/help", {replace: true})}}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          Help
        </Button> */}
             
            
          </Box>
        </Toolbar>
      
    </AppBar>
        </HideOnScroll>
        <Toolbar />
        </ThemeProvider>
        {/* This makes sure that the Navbar does not overlap with main web text */}
        <div style={{ paddingTop: `${navHeight -60}px`}} />
        </div>  
    );
  }
  