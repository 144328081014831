import { AlgoButton, Button, Modal, Card, Flex, Text, Box, Heading,} from "pipeline-ui"
import { ArrowBack, Algo, LockOpen, DoneAll, Home } from '@pipeline-ui/icons'
import { ALGOButton } from "../components/AlgoButton"
import { useNavigate} from "react-router-dom"
import Write from "../components/CreateFile"
import '../index.css'
import { createASA, optInToAsset } from '../myAlgo/myAlgoFunctions'
import FormData from 'form-data'
import axios from 'axios'
import { useState } from "react"
import { BallTriangle } from 'react-loading-icons'
import wclogo from '../wc.svg'
// import WalletConnect from "@walletconnect/client"
// import QRCodeModal from "algorand-walletconnect-qrcode-modal"
import algosdk from "algosdk"
import { formatJsonRpcRequest } from "@json-rpc-tools/utils"
import * as crypto from "crypto";
import ToggleButtons from "../components/ToggleStorage"
import ToggleNFTButtons from "../components/ToggleNFTStandard"
import ReactTooltip from 'react-tooltip';
// import { sendEmailSMTP } from '../sendGrid/sendMail'
import {PeraWalletConnect} from "@perawallet/connect"

export function Confirmation(props) {
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const errorMsg = error 

  const [optIn, setOptInResult] = useState("")
  const optInStatus = optIn

  const [assetID, setAssetID] = useState("")
  const [MetadataFile, setMetadataFile] = useState("")
  const [transactionIDs, setTransactionIDs] = useState(["",""])

  const [LoadingisOpen, setLoadingIsOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [Private, setPrivate] = useState(false);
  const handlePrivate = (event, newPrivate) => {
    if (newPrivate !== null) {
      setPrivate(newPrivate);
  };
    }
    
  const [nftStandard, setNftStandard] = useState("arc69"); //ARC69 or ARC3
  const handleNFTStandard = (event, newStandard) => {
    if (newStandard !== null) {
      setNftStandard(newStandard);
      };
    }


  const [Metadata, setMetadata] = useState({});

  const [connection, setConnection] = useState("") //refers to MyAlgo or WalletConnect

  const closeModal = e => {
    e.preventDefault();
    setIsOpen(false);
  };

  const openModal = e => {
    e.preventDefault();
    setIsOpen(true);
  };
  const mintNFDResult =  {
    assetID,
    transactionIDs,
  }

  const [IpfsHash, setIpfsHash] = useState("")
  const ipfsUrl = IpfsHash

  const [IpfsMetadataHash, setIpfsMetadataHash] = useState("")
  const ipfsMetadataUrl = IpfsMetadataHash

  const [IpfsPreviewHash, setIpfsPreviewHash] = useState("")
  const ipfsPreviewUrl = IpfsPreviewHash

  const filterEmptyList = props.formValues.filter((data) => 
      data.key !== '' && data.value !== ''
  )
  const optionalCustomDataFields = filterEmptyList.map((data) => {

    return (
        <h3 key={`${data.key}${data.value}`}>
            {data.key}: {data.value}
        </h3>
    );
  })

  const finalList = filterEmptyList


  function handleClick() {
    navigate("/", {replace: true});
    document.location.reload()

  }

  async function mintwalletConnectNFD(newUnitName, newAssetName, fileUrl,
     description, documentPage, documentWordCount, documentLocale, previewFileUrl,
    fileBuffer, previewFileType, author, authorEmail, fileCreationDate, receiverEmail, customFields,
    nftStandard) {

      try{
        setError("")
          //init wallet connect 
          // Create a connector
          const peraWallet = new PeraWalletConnect({
            chainId: process.env.NODE_ENV === 'development' ? 416002 : 416001
        });
        peraWallet.disconnect();

        peraWallet
        .connect()
        .then(async (accounts) => {

          const senderWalletAddr = accounts[0]
          const baseServer = process.env.REACT_APP_ALGODCLIENT
          const port = '';
          const token = {
              'X-API-Key': ''
          }

          const algodClient = new algosdk.Algodv2('', baseServer, port)
          const params = await algodClient.getTransactionParams().do()

            // Whether user accounts will need to be unfrozen before transacting    
          const defaultFrozen = false
          // Used to display asset units to user    
          const unitName = newUnitName
          // Friendly name of the asset    
          const assetName = newAssetName
          const url = fileUrl !== "" ? "https://gateway.pinata.cloud/ipfs/"+fileUrl : ""

          const previewUrl = previewFileUrl !== "" ? "https://gateway.pinata.cloud/ipfs/"+previewFileUrl : ""
          const previewUrlData = previewFileUrl !== "" ? "ipfs://"+ previewFileUrl : ""
        
          const previewFileDataType =  previewFileType
          const creator = author
          const creatorEmail = authorEmail
          const creationDate = fileCreationDate

          //manager account can destroy asset
          const managerAddr = senderWalletAddr // OPTIONAL: FOR DEMO ONLY, USED TO DESTROY ASSET WITHIN
          
          //const managerAddr = undefined; 
          // Specified address is considered the asset reserve
          // (it has no special privileges, this is only informational)
          const reserveAddr = undefined
          // Specified address can freeze or unfreeze user asset holdings   
          const freezeAddr = undefined
          // Specified address can revoke user asset holdings and send 
          // them to other addresses    
          const clawbackAddr = undefined
          const decimals = 0
          const total = 1; // how many of this asset there will be

          const encoder = new TextEncoder(); // always utf-8
          
          const notes =
          {
            creator: senderWalletAddr,
            description: description,
            external_link: previewUrlData,
            mime_type: previewFileDataType,
            document_link: url,
            properties: {
              pages: documentPage === "" ? "Nil" : documentPage,
              wordCount: documentWordCount === "" ? "Nil" : documentWordCount,
              language: documentLocale === "" ? "Nil" : documentLocale,
              creation_date: fileCreationDate === "" ? "Nil" : fileCreationDate,
            },
            sensitive_content: false,
            standard: nftStandard,
          }
          const userCustomFields2 = customFields;

    const updatedCustomInput = userCustomFields2.map((data) => {
      const keyData = data.key;
      //create new key value
      const newFieldObject = { [keyData]: data.value };
      //add new key values to properties object
      const updatedMetadata = Object.assign(notes.properties, newFieldObject);

      //add new properties object to existing preset metadata
      const newFieldObject2 = { properties: updatedMetadata };

      const customMetadata = Object.assign(notes, newFieldObject2);

      return customMetadata;
    });

    const createNFDNote =
      customFields.length === 0
        ? encoder.encode(JSON.stringify(notes))
        : encoder.encode(JSON.stringify(updatedCustomInput[0]));
          //form document integrity data from file buffer
          const fileDataBuffer = fileBuffer
          const hashDocument = crypto.createHash('sha256')
          hashDocument.update(fileDataBuffer);
          const hashDocumentBase64 = hashDocument.digest("base64");
          const documentIntegrity = "sha256-" + hashDocumentBase64;

          const userCustomFields = customFields
          const nftARC = nftStandard
          console.log("ok")
          const intialMetadataObj = nftARC === 'arc3' ?
          {
              decimals: decimals,
              document_url: url,
              document_integrity: documentIntegrity,
              document_mimetype: "application/pdf",   //document
              name: assetName,
              properties: {
                  creator: creator,
                  creator_email: creatorEmail, 
                  creation_date: creationDate,
                  description: description,
                  document_words: documentWordCount,
                  document_pages: documentPage,
                  document_locale: documentLocale,
                  image_mimetype: previewFileDataType, //image type .png, .jpeg, ,jpg
              },
              unitName: unitName,
          } : 
          //ARC69
          {
              standard: "arc69",
              description: description,
              mime_type: "application/pdf", 
              external_url: "",
              document_integrity: documentIntegrity,
              properties: {
                      unitName: unitName,
                      assetName: assetName,
                      document_url: url,
                      document_words: documentWordCount,
                      document_pages: documentPage,
                      document_locale: documentLocale,
                      preview_url: previewUrl, //image ipfs url
                      image_mimetype: previewFileDataType, //image type .png, .jpeg, ,jpg
                      creator: creator,
                      creator_email: creatorEmail, 
                      creation_date: creationDate,
                      }
          }
          

          const updatedCustomMetadata = userCustomFields.map((data) => {
            const keyData = data.key
            //create new key value
            const newFieldObject = {[keyData]: data.value}
            //add new key values to properties object
            const updatedMetadata = Object.assign(intialMetadataObj.properties, newFieldObject)
    
            //add new properties object to existing preset metadata
            const newFieldObject2 =  {properties: updatedMetadata}
            const editedMetadataObj = nftARC === 'arc3' ? 
            {
                decimals: decimals,
                document_url: url,
                document_integrity: documentIntegrity,
                document_mimetype: "application/pdf",   //document
                name: assetName,
                unitName: unitName,
            } : 
            {
                standard: "arc69",
                description: description,
                mime_type: "application/pdf", 
                external_url: "" ,
                document_integrity: documentIntegrity,
            }
    
            const customMetadata = Object.assign(editedMetadataObj, newFieldObject2)
    
            return customMetadata
        })

        const metadataObj = customFields.length === 0 ? intialMetadataObj : updatedCustomMetadata[0]
            //set metdadata to react variable
            setMetadata(metadataObj)

          const metadataBuffer = Buffer.from(JSON.stringify(metadataObj))
          const hash = crypto.createHash('sha256');
          hash.update(metadataBuffer);
          const metadata = new Uint8Array(hash.digest());
  
          //-------------1st transaction for creation of Asset----------------------//
          // signing and sending "txn" allows "addr" to create an asset 
          const txn1 = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
              from: senderWalletAddr,
              total,
              decimals,
              assetName,
              unitName,
              assetURL: previewUrlData,
              assetMetadataHash: metadata,
              defaultFrozen,
              freeze: freezeAddr,
              manager: managerAddr,
              clawback: clawbackAddr,
              reserve: reserveAddr,
              note: createNFDNote,
              suggestedParams: params,})

          //----------2nd transaction for payment of sign ASA to hot wallet-------------//
          // asset id: 66031611 Test ASA
          let params2 = await algodClient.getTransactionParams().do()
          //comment out the next two lines to use suggested fee
          params2.fee = 1000
          params2.flatFee = true

          const sender = senderWalletAddr
          const recipient =  'FS3O5XZLGB7SJGH6YNGVKSUPNT255QXXCEQMLP3QEAQILACKPGG7MIQGZI' //test hot wallet
          const revocationTarget = undefined
          const closeRemainderTo = undefined
          //Amount of the asset to transfer
          // const amount = process.env.NODE_ENV === 'development' ? 500 : 50000000// equals to 5 TEST ASA (2.d.p) Mainnet SIGN(7 d.p.)
          
          if(typeof process.env.REACT_APP_PAYMENT_AMOUNT === 'undefined'){
            throw 'asset amount undefined error'
          }
          const amount = parseInt(process.env.REACT_APP_PAYMENT_AMOUNT)
          const enc = new TextEncoder() // always utf-8
          const note = enc.encode("Payment of SIGN ASA for minting NFD")

          const assetId = parseInt(process.env.REACT_APP_ASSETID)
          // signing and sending "txn" will send "amount" assets from "sender" to "recipient"
          let txn2 = algosdk.makeAssetTransferTxnWithSuggestedParams(sender, recipient, closeRemainderTo, revocationTarget,
          amount,  note, assetId, params2)

          const txns = [txn1, txn2]
         
          const multipleTxnGroups = [
            {txn: txn1, signers: [senderWalletAddr]},
            {txn: txn2, signers: [senderWalletAddr]}
          ];

          const signedTxnGroups = await peraWallet.signTransaction([multipleTxnGroups]);

          let transactionIds = []

          for (const signedTxnGroup of signedTxnGroups) {
            const {txId} = await algodClient.sendRawTransaction(signedTxnGroup).do();
        
            console.log(`txns signed successfully! - txID: ${txId}`);
            await algosdk.waitForConfirmation(algodClient, txId, 4)
            const ptx = await algodClient.pendingTransactionInformation(txId).do();
           
            const assetID = ptx["asset-index"];
            if(assetID != undefined){
              setAssetID(assetID)
            }
            

            transactionIds.push(txId)
          }

          setTransactionIDs(transactionIds)

            //handle metadata
              setIpfsHash(url)
              setMetadata(metadataObj)
              setIpfsPreviewHash(previewUrl)

              let output; //create metadata.json file

              const data = metadataObj
                output = JSON.stringify({data});
                  const blob = new Blob([output]);
                  const file = new File([blob], "metadata.json", {lastModified: new Date(), type: "application/json"});
                  console.log(file);
                  
                  setMetadataFile(file);
                  //duplicate code
                  if ( Private === false) {
                    const IPFSMetadataUrlResult = await handleMetadataIPFS(data)
                    if (IPFSMetadataUrlResult.IpfsHash === null){
                     setLoadingIsOpen(false)
                     throw "Error"
                     
                    } else {
                      setIsOpen(true)
                      setLoadingIsOpen(false)
                      setIpfsMetadataHash("https://gateway.pinata.cloud/ipfs/"+ IPFSMetadataUrlResult.IpfsHash)} }
                      else {
                        setIsOpen(true)
                        setLoadingIsOpen(false)
                        setIpfsMetadataHash("")
                      }
                  setConnection('walletConnect')
                  peraWallet.disconnect();
            // }catch(error){
            //   const catchError = error.toString()
            //   console.error('caught error: ' + catchError)
            //   const assetASA = process.env.NODE_ENV === 'development' ? 'Test_credit' : 'SIGN'
            //   if(catchError.includes(`asset ${process.env.REACT_APP_ASSETID} missing from`)){
            //     error = `Wallet Not opt-in to asset ${process.env.REACT_APP_ASSETID}`
            //   } else if(catchError.includes('underflow on subtracting')){
            //     error = `Wallet does not have enough ${assetASA} asa, 25 ${assetASA} asa required.`
            //   } else if (catchError.includes('tried to spend {1000}')){
            //     error = 'Wallet does not have enough algos to complete transaction.'
            //   } else {
            //     error = error
            //   }
            
            //   peraWallet.disconnect()
            //   setError(error)
            //   setLoadingIsOpen(false)
            //   throw error
            // }
      
        })              
      } catch (err) {
        console.log('error: ' + err)
        alert(error)
         return err
      }
  }

  //WIP Wallet Connect
  //props, unitName, assetName, note, description, pages, wordCount, locale
  async function walletConnect(props, unitName, assetName,
    description, pages, wordCount, locale,
    author, authorEmail, createdDate){
      setError("")
      setLoadingIsOpen(true)
    //pin file to IPFS and return url to mint NFT

    const fileBufferString = await fileToData(props.file);
    const {previewIpfsHash, documentIpfsHash, previewFileType }
     = await toggleStorageDocument()
    // const IPFSUrlResult = await handleIPFS(props)
    // if (IPFSUrlResult.IpfsHash === null){
    //  setLoadingIsOpen(false)
    //  throw "Error"
    // }
    // console.log(IPFSUrlResult.IpfsHash)

    // const IPFSPreviewUrlResult = await handleIPFSPreview(props)
    // console.log(IPFSPreviewUrlResult.IpfsHash)
    // const fileBufferString = await fileToData(IPFSUrlResult.fileBuffer)

    //main function of minting and handling all metadata, ipfs urls
    await mintwalletConnectNFD(unitName, assetName, documentIpfsHash, 
       description, pages, wordCount, locale, previewIpfsHash,
      fileBufferString,previewFileType, author, authorEmail, createdDate, props.personalEmail, finalList, nftStandard)
    }
    

  async function mintNFD(props, wallet, unitName, assetName,
     description, pages, wordCount, locale,
     author, authorEmail, createdDate) {
    setLoadingIsOpen(true)
    setError("")
    if(wallet === "" || wallet == null){
      setLoadingIsOpen(false)
      throw setError("Please connect your Algo wallet.")
    }

    //pin file to IPFS and return url to mint NFT
  
    const fileBufferString = await fileToData(props.file);
    const {previewIpfsHash, documentIpfsHash, previewFileType }
     = await toggleStorageDocument()
    
    const resultOutput = (
      await createASA(wallet, unitName, assetName, documentIpfsHash, 
         description, pages, wordCount, locale, previewIpfsHash,
        fileBufferString, previewFileType, author, authorEmail, createdDate, finalList, nftStandard ))
  //   {
  //     result : 'Fail',
  //     assetID: null,
  //     transactionIDs : []
  // }
        console.log(resultOutput.result)
  // console.log(IPFSMetadataUrlResult.IpfsHash)
    if(resultOutput.result === 'Fail'){

      const myAlgoError = (resultOutput.errMsg).toString()
      const assetASA = process.env.NODE_ENV === 'development' ? 'Test_credit' : 'SIGN'
      if(myAlgoError.includes('asset 66031611 missing from')){
        setError(`Wallet Not opt-in to asset ${process.env.REACT_APP_ASSETID}`)
      } else if(myAlgoError.includes('underflow on subtracting')){
        setError(`Wallet does not have enough ${assetASA} asa, 25 ${assetASA} asa required.`)
      } else if (myAlgoError.includes('tried to spend {1000}')){
        setError('Wallet does not have enough Algos to complete transaction.')
      } else {
        setError(resultOutput.errMsg)
      }
      setLoadingIsOpen(false)
      setIsOpen(false)

      } else {
        setAssetID(resultOutput.assetID),
        setTransactionIDs(resultOutput.transactionIDs)
        if (documentIpfsHash !=="") {
        setIpfsHash("https://gateway.pinata.cloud/ipfs/"+documentIpfsHash) 
        } else {setIpfsHash("")
      
      }
      setMetadata(resultOutput.metadata)
      if (previewIpfsHash !=="") {setIpfsPreviewHash("https://gateway.pinata.cloud/ipfs/"+previewIpfsHash)}
      else {setIpfsPreviewHash(previewIpfsHash)}


      setConnection('MyAlgo')
      let output; //create metadata.json file
      console.log(resultOutput.metadata)
      const data = resultOutput.metadata
      console.log(data)
        output = JSON.stringify({data});
        console.log(output)
          const blob = new Blob([output]);
          console.log(blob)
          const file = new File([blob], "metadata.json", {lastModified: new Date(), type: "application/json"});
          console.log(file);
          
          setMetadataFile(file);
          //duplicate code
          if ( Private === false) {
            const IPFSMetadataUrlResult = await handleMetadataIPFS(data)
            if (IPFSMetadataUrlResult.IpfsHash === null || resultOutput.result === 'Fail'){
             setLoadingIsOpen(false)
             throw "Error"
             
            } else {
              setIsOpen(true)
              setLoadingIsOpen(false)
              setIpfsMetadataHash("https://gateway.pinata.cloud/ipfs/"+ IPFSMetadataUrlResult.IpfsHash)} }
              else {
                setIsOpen(true)
                setLoadingIsOpen(false)
                setIpfsMetadataHash("")
              }
          
            
            //SMTP Function
            // const sendEmail = await sendEmailSMTP(props.personalEmail, unitName, assetName, description, pages, wordCount, locale,
            //   author, authorEmail, createdDate, resultOutput.metadata)
            // console.log(sendEmail)
            //Send NFD completed email
            //  createEmail(process.env.REACT_APP_SENDGRID_API_KEY,props.personalEmail, unitName, assetName,
            //   description, pages, wordCount, locale,
            //   author, authorEmail, createdDate)
           
            // const hostname = process.env.NODE_ENV === 'development' ? window.location.hostname + ':3001' : window.location.hostname
            // const hostConfig = process.env.NODE_ENV === 'development' ? 'http' : 'https'
            // const sendConfirmationEmail = () => {
            //   fetch(`${hostConfig}://${hostname}/send-email`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ email: props.personalEmail, unitName, assetName, desc: description, pages,
            //       wordCount, language: locale, creator: author, creatorEmail: authorEmail, creationDate: createdDate, file: resultOutput.metadata })
            //   });
            // }
            // sendConfirmationEmail()
          }
   
    // setError(result.result)
  }

  async function handleIPFS(props){
      // console.log(file)
      //   Add code here to upload file to server
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      const fileBufferData =  props.file
      
      console.log(props.file)
      let data = new FormData();
      data.append('file', props.file)
      const metadata = JSON.stringify({
        name: `${props.file.name}`,
        keyvalues: {
            filetype: `${props.file.type}`,
            size: `${props.file.size}`,
        }
    });
    data.append('pinataMetadata', metadata);
    return axios
        .post(url, data, {
            maxBodyLength: undefined, //this is needed to prevent axios from erroring out with large files
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
            }
        })
        .then(function (response) {
            //handle response here
            // setIpfsHash("https://gateway.pinata.cloud/ipfs/"+response.data.IpfsHash)
            return {
              IpfsHash: response.data.IpfsHash,
              PinSize: response.data.PinSize,
              Timestamp: response.data.Timestamp,
              fileBuffer: fileBufferData
            }
            
            
            //sample output
            // {
            //     IpfsHash: 'QmavDxiYiRF4okjHGMAQe7HnJLY3NQB2MnhECkXCgPSPox',
            //     PinSize: 492458,
            //     Timestamp: '2022-01-25T07:08:12.981Z'
            //   }
            //https://gateway.pinata.cloud/ipfs/{IpfsHash}
        })
        .catch(function (error) {
            //handle error here
            if(error = 'Error: Request failed with status code 400'){
              error = 'No file uploaded.'
            }
            if(error = 'Error: Request failed with status code 403'){
              error = 'IPFS key max limit reached.'
            }
            setError(error)
            console.log(error)
            return {
              IpfsHash: null,
              errorMsg: error,
            }
        });
    }

    async function handleMetadataIPFS(file){
      // console.log(file)
      //   Add code here to upload file to server
      const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    return axios
        .post(url, file, {
            headers: {
                pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
            }
        })
        .then(function (response) {
            //handle response here
            // setIpfsHash("https://gateway.pinata.cloud/ipfs/"+response.data.IpfsHash)
            return {
              IpfsHash: response.data.IpfsHash,
              PinSize: response.data.PinSize,
              Timestamp: response.data.Timestamp,
            }
            
            
            //sample output
            // {
            //     IpfsHash: 'QmavDxiYiRF4okjHGMAQe7HnJLY3NQB2MnhECkXCgPSPox',
            //     PinSize: 492458,
            //     Timestamp: '2022-01-25T07:08:12.981Z'
            //   }
            //https://gateway.pinata.cloud/ipfs/{IpfsHash}
        })
        .catch(function (error) {
            //handle error here
            if(error = 'Error: Request failed with status code 400'){
              error = 'No file uploaded.'
            }
            setError(error)
            console.log(error)
            return {
              IpfsHash: null,
              errorMsg: error,
            }
        });
    }

    async function handleIPFSPreview(props){
      
      //   dont call pinata api if user doesnt upload preview image
      if(props.PreviewFile == ""){
        return {
          IpfsHash: "",
          PinSize: "",
          Timestamp: "",
          fileType: ""
        }
      }

      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      let data = new FormData();
      console.log(props.PreviewFile)
      data.append('file', props.PreviewFile)
      const metadata = JSON.stringify({
        name: `${props.PreviewFile.name}`,
        keyvalues: {
            filetype: `${props.PreviewFile.type}`,
            size: `${props.PreviewFile.size}`,
        }
        
    });
    const previewFileType = props.PreviewFile.type
    console.log(metadata)
    console.log(data)
    data.append('pinataMetadata', metadata);
    return axios
        .post(url, data, {
            maxBodyLength: undefined, //this is needed to prevent axios from erroring out with large files
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
            }
        })
        .then(function (response) {
            return {
              IpfsHash: response.data.IpfsHash,
              PinSize: response.data.PinSize,
              Timestamp: response.data.Timestamp,
              fileType: previewFileType
            }
        })
        .catch(function (error) {
            //handle error here
            if(error = 'Error: Request failed with status code 400'){
              error = 'No image file uploaded.'
            }
            console.log(error)
            return {
              IpfsHash: "",
              errorMsg: error,
            }
        });
    }

    const fileToData = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsBinaryString(file)
      })
    }

    async function toggleStorageDocument() {
      if ( Private === false) {
        const IPFSUrlResult = await handleIPFS(props)
        if (IPFSUrlResult.IpfsHash === null){
         setLoadingIsOpen(false)
         throw "Error"
         
        }
        console.log(IPFSUrlResult.IpfsHash)
        const documentIpfsHash = IPFSUrlResult.IpfsHash;
        
        const IPFSPreviewUrlResult = await handleIPFSPreview(props)
        console.log(IPFSPreviewUrlResult.IpfsHash)
        if (IPFSPreviewUrlResult.IpfsHash === null){
          setLoadingIsOpen(false)
          throw "Error"
          
         }
        const previewIpfsHash = IPFSPreviewUrlResult.IpfsHash
        const previewFileType = IPFSPreviewUrlResult.fileType;
        return {documentIpfsHash, previewFileType, previewIpfsHash}
        
      } else {
        const documentIpfsHash = ""
        const previewFileType = props.PreviewFile.type
        const previewIpfsHash = ""
        return {documentIpfsHash, previewFileType, previewIpfsHash }
    }
    }
    

    return (
<div align="center" className="card">
  
  <h2>{"1. Confirm NFD Details & Choose Storage Options"}</h2>
  
<h3>{"Asset Name: " + props.assetName}</h3>
<h3>{"Unit Name: " + props.unitName}</h3>
{/* <h3>{"Your Email: " + props.personalEmail}</h3> */}
{optionalCustomDataFields}


{(() => {
          const  description  = props.description;

          if (description.length > 0) {
            return <h3>{ "Description: " + props.description}</h3>
          }
          // return nothing to display if empty
          return
        })()}

{(() => {
          const  documentPage  = props.documentPage;

          if (documentPage.length > 0) {
            return <h3>{ "Document Pages: " + props.documentPage}</h3>
          }
          // return nothing to display if empty
          return
        })()}

{(() => {
          const  documentWordCount  = props.documentWordCount;

          if (documentWordCount.length > 0) {
            return <h3>{ "Document Word count: " + props.documentWordCount}</h3>
          }
          // return nothing to display if empty
          return
        })()}

{(() => {
          const  documentLocale  = props.documentLocale;

          if (documentLocale.length > 0) {
            return <h3>{ "Document Locale: " + props.documentLocale}</h3>
          }
          // return nothing to display if empty
          return
        })()}
{(() => {
          const creator  = props.creator;

          if (creator.length > 0) {
            return <h3>{ "Creator: " + props.creator}</h3>
          }
          // return nothing to display if empty
          return
        })()}

{(() => {
          const creatorEmail  = props.creatorEmail;

          if (creatorEmail.length > 0) {
            return <h3>{ "Creator Email: " + props.creatorEmail}</h3>
          }
          // return nothing to display if empty
          return
        })()}

{(() => {
          const creationDate  = props.creationDate;

          if (creationDate.length > 0) {
            return <h3>{ "Creation Date: " + props.creationDate}</h3>
          }
          // return nothing to display if empty
          return
        })()}



      <p>{"Document Name: " + props.file.name}</p>
      <p>{"Document Type: " + props.file.type}</p>
      <p>{"Document Size: " + props.file.size + " Bytes"}</p>
      <a style = {{fontSize : '1.6em', fontWeight : 'bolder', textDecoration: 'underline'}}>{"Storage Options"}</a>
      <div></div>
      {/* <form> */}
      <ToggleButtons handlePrivate={handlePrivate} Private={Private} />
      <a className="toolTip" style = {{paddingLeft:"0px"}} data-tip="If stored privately, NFD will have limited functionality and cannot be listed on an exchange."></a>
      <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
          
      <div style ={{marginTop: '20px'}}> <a style = {{fontSize : '1.6em', fontWeight : 'bolder', textDecoration: 'underline'}}>{"NFT Standard"}</a></div>
      <div></div>
      <ToggleNFTButtons handleNFTStandard={handleNFTStandard} nftStandard={nftStandard} />

      <h2>{"2. Connect your Wallet with pera mobile wallet"}</h2>
  <div style = {{display: 'flex'}}>
    
        <button style = {{width: '30em'}} className='wc-button' onClick={() => walletConnect(props, props.unitName, props.assetName,
         props.description, props.documentPage, props.documentWordCount, props.documentLocale,
      props.creator, props.creatorEmail, props.creationDate)}> 
        <img src ={wclogo} style = {{width: '30px', height: '30px', resizeMode: 'contain', paddingTop: '0px', paddingRight: '5px'}} />
        <a style = {{margin: 'auto', paddingRight: '1em'}} >{"Pera Wallet" }</a> </button>
  </div>

    <h3>{"Wallet Address: " + props.address}</h3>
    <h3>{"Connect to MyAlgo with popups enabled. The cost to mint your shiny new NFD is 25 SIGN."}</h3>
    <div>
    <Button className="back-button" onClick={() => mintNFD(props,props.address, props.unitName, props.assetName, 
       props.description, props.documentPage, props.documentWordCount, props.documentLocale,
      props.creator, props.creatorEmail, props.creationDate)}  >
    <Algo></Algo><a>{"Mint NFD Now (For MyAlgo)" }</a> </Button>
    </div>
    <br></br>
    <div>
    </div>
    <div><Button className="back-button"  onClick={() => navigate("/mint", {replace: true})}  ><ArrowBack></ArrowBack><a>Back</a></Button></div>
    <h5 style = {{color: "red"}}>{"" + errorMsg}</h5>
   {/* </form> */}
    <div>
    {/* <Button onClick={openModal}>Open Modal</Button> */}
    <Modal isOpen={isOpen}>
          <Card width={"900px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={3}
              mr={3}
              onClick={closeModal}
            />

            <Box p={4} mb={3}>
              <Heading.h3>Transaction Successful!<DoneAll color="green"></DoneAll></Heading.h3>
              <div>
              <h3>
                NFD Asset ID:{" "}
                <a href={`${process.env.REACT_APP_EXPLORER_URL}/asset/${assetID}/`} target="_blank" rel="noopener noreferrer">
                  {assetID}
                </a>
              </h3>

              {(() => {
                  const getWalletMethod  = connection;
                  const groupId = mintNFDResult.transactionIDs[1]
                  const link = encodeURIComponent(groupId)
                  const algoexplorer = process.env.REACT_APP_EXPLORER_URL

                  if (getWalletMethod === 'walletConnect') {
                    return (
                      <>
                     <div>
                      <h3>{"Transaction Ids: " }</h3>
                    </div>
                    <div style ={{paddingBottom: '10px'}}>
                      <a  href={algoexplorer + "/tx/" + mintNFDResult.transactionIDs[0]} target="_blank">{mintNFDResult.transactionIDs[0]}</a>
                    </div>
                    <div style ={{paddingBottom: '10px'}}>
                      <a  href={algoexplorer + "/tx/" + mintNFDResult.transactionIDs[1]} target="_blank">{mintNFDResult.transactionIDs[1]}</a>
                    </div>
                      </>
                    )
                  } else if(getWalletMethod === 'MyAlgo'){

                   return(
                    <>
                    <div>
                      <h3>{"Transaction Ids: " }</h3>
                    </div>
                    <div style ={{paddingBottom: '10px'}}>
                      <a  href={algoexplorer + "/tx/" + mintNFDResult.transactionIDs[0]} target="_blank">{mintNFDResult.transactionIDs[0]}</a>
                    </div>
                    <div style ={{paddingBottom: '10px'}}>
                      <a  href={algoexplorer + "/tx/" + mintNFDResult.transactionIDs[1]} target="_blank">{mintNFDResult.transactionIDs[1]}</a>
                    </div>
                    
                    </>
                  )
                  } else {
                    return
                  }
                })()}
              {(() => {
                const documentUrl  = ipfsUrl

                if (documentUrl.length > 0) {
                  return (
                    <>
                    <h3 >{"IPFS PDF Url: "}</h3>
                  <a href={ipfsUrl} target="_blank">{ipfsUrl}</a>
                    </>
                  )
                } else {
                   // return nothing to display if empty
                  return <h3 >{"IPFS PDF Url: -Not Stored-"}</h3>
                }
               
              })()}

              {(() => {
                const imageUrl  = ipfsPreviewUrl

                if (imageUrl.length > 0) {
                  return (
                    <>
                    <h3 >{"IPFS Preview Url: "}</h3>
                    <a href={ipfsPreviewUrl} target="_blank">{ipfsPreviewUrl}</a>
                    </>
                  )
                } else if (( Private === true)) {
                   // return nothing to display if empty
                  return <h3 >{"IPFS Preview Url:  -Not Stored-"}</h3>
                } else {
                  return <h3 >{"IPFS Preview Url:  -None Provided-"}</h3>
                }
               
              })()}
            

              {(() => {
                const metadataUrl  = ipfsMetadataUrl

                if (metadataUrl.length > 0) {
                  return (
                    <>
                    <h3 >{"IPFS metadata.json Url: "}</h3>
                    <a href={ipfsMetadataUrl} target="_blank">{ipfsMetadataUrl}</a>
                    </>
                  )
                } else  {
                   // return nothing to display if empty
                  return <h3 >{"IPFS metadata.json Url:  -Not Stored-"}</h3>
                } 
               
              })()}

              <Write metadata={Metadata} setMetadataFile={setMetadataFile} handleMetadataIPFS={handleMetadataIPFS} />
              </div>
              <Text>Return to Home page to mint another</Text>
            </Box>

            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button className="home" onClick={handleClick}><Home ></Home><a>Home</a></Button>
            </Flex>
          </Card>
        </Modal>
        <Modal isOpen={LoadingisOpen}>
          <BallTriangle stroke="#06bcee" fill="transparent" speed={1.5} />
          <p style = {{color: '#06bcee'}} >Loading...</p>


        </Modal>
    </div>
   

</div>
    )
}

export default Confirmation
