import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import '../index.css'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  innerWidth: '10px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};



function PreviewDrop(props) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: '.png, .jpeg, .jpg', maxFiles: 1, onDrop: (acceptedFiles) => {
    props.setFile(
      acceptedFiles[0]
    );
  }});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {borderColor: "#d3d3d3"}),
  }), [
    isFocused,
  ]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="container">
      <h4>Upload an image for thumbnail views
        <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="This can be used for others to get a preview of your document before buying your NFD "></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
      </h4>
      
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drop or click here to select file</p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
      
    </div>
  );
}

export default PreviewDrop
