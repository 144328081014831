import React, {useState} from 'react'
import '../index.css'
import { useNavigate, } from "react-router-dom"
import {ArrowBack, Algo, DoneAll} from '@pipeline-ui/icons'
import { Button, Modal, Card, Heading, Box, Flex, Text } from "pipeline-ui"
import logo from '../signnet.png'
import AuthenticateDrop from '../components/AuthenticateDrop'
import * as crypto from "crypto";
import ReactTooltip from 'react-tooltip';
import StyledDropzone from '../components/DropDragFile';

export function AuthenticatePage(props) {
    const navigate = useNavigate()
    const [MetaDataFile, setMetaDataFile] = useState("");
    const metadata = MetaDataFile
    const [PDFFile, setPDFFile] = useState("");
    const pdfFile = PDFFile
    const [MetadataHash, setMetadataHash] = useState("")
    const hashdata = MetadataHash
    const [error, setError] = useState("");
    const errorMsg = error;
    const [DocumentIsOpen, setDocumentIsOpen] = useState(false);
    const closeDocumentModal = e => {
        e.preventDefault();
        setDocumentIsOpen(false);
      };
    const [MetadataIsOpen, setMetadataIsOpen] = useState(false);
    const closeMetadataModal = e => {
        e.preventDefault();
        setMetadataIsOpen(false);
      };

    const handleMetadata = e => {
        setMetadataHash(e.target.value);
      };

   async function authenticate(metadataFile, hashdata, pdfFile ) {
        console.log(metadataFile)
    if(metadataFile=== "" || pdfFile === "") { setError("Metadata file or Document file missing")} 
    else {   

      const text = await fileToText(metadataFile)
      console.log(text)
      const flatJson = JSON.parse(text.toString())
      const integrity = flatJson["document_integrity"];
      console.log(integrity)
      const metadataBuffer = Buffer.from(text);
      // console.log(metadataBuffer)
      const hash = crypto.createHash('sha256');
      hash.update(metadataBuffer);
      const metadata = new Uint8Array(hash.digest());
      // console.log(metadata)
      const b64 = Buffer.from(metadata).toString('base64');
      // console.log(hashdata)
  

        const content = await fileToData(pdfFile)
        const buffer = Buffer.from(content);
        const fileHash = crypto.createHash('sha256');
        fileHash.update(buffer)
        const hashDocumentBase64 = fileHash.digest("base64");
        console.log(hashDocumentBase64)
        const documentIntegrity = "sha256-" + hashDocumentBase64;
      
      console.log("metadataHash : " + b64)
      console.log("Document Hash : " + documentIntegrity)
      if (b64 === hashdata && documentIntegrity === integrity) {setMetadataIsOpen(true)} 
      else if (hashdata.length < 1 ) {
        setError("Metadata Hash is empty")}
      else if (b64 !== hashdata && documentIntegrity === integrity) {
        setError("Document file matches Metadata.json file but Metadata.json does not match Metadata Hash! Both files are not authentic to this Metadata Hash.")}
      else if (documentIntegrity!== integrity && b64 === hashdata ) {
        setError("Metadata.json file is authentic but document file does not match!")
      }
      else {
        setError("Both files are not authentic!")}

    
    




    // console.log(JSON.stringify(blob))
 
        // console.log(metadata)
        // console.log(hashdata)
        // if (metadata === hashdata) { setIsOpen(true)
        

        // } else { setError("The Metadata file is not authentic")}


    } }

     const fileToData = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsBinaryString(file)
      })
    }

     const fileToText = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsText(file)
      })
    }

    // const getIntegrity = (JsonText) => { JsonText.map()}






    return (
    
    <div align="center" className="card">    
        <img alt="Create Non-Fungible Document" src={logo} width="300"></img><br></br>
        <div className="toggle-select">
        </div> 
        <h4>Authenticate NFD </h4>
        <form>

        <label className="form-label">
          Metadata Hash
          <input type="text" placeholder="Metadata Hash" className="form-control" value={props.note} onChange={handleMetadata} /> 
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Find the MetaData Hash by entering your NFD Asset ID on any Alogrand blockchain explorer"></a>
          <ReactTooltip place="top" type="dark" effect="solid"/>
        </label>
          <AuthenticateDrop setFile={setMetaDataFile}/>
        </form>
        <div>
        </div>
        <form>
          <StyledDropzone setFile={setPDFFile}/>
        </form>
        <div>
        <Button className="back-button"  onClick={() => navigate("/", {replace: true})}  ><ArrowBack></ArrowBack><a>Back</a></Button>
        <Button className="back-button" onClick={() => authenticate(metadata, hashdata, pdfFile)}  >
                <Algo></Algo><a>{"Authenticate" }</a> 
            </Button>
        </div>
        <h5 style = {{color: "red"}}>{"" + errorMsg}</h5>

        <Modal isOpen={MetadataIsOpen}>
          <Card width={"900px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={3}
              mr={3}
              onClick={closeMetadataModal}
            />

            <Box p={4} mb={3}>
              <Heading.h3>Files are authentic!<DoneAll color="green"></DoneAll></Heading.h3>
              <div>
              </div>
              <Text>Authenticate Another NFD</Text>
            </Box>

            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button className="home" onClick={closeMetadataModal}><a>Home</a></Button>
            </Flex>
          </Card>
        </Modal>
        {/* <Modal isOpen={DocumentIsOpen}>
          <Card width={"900px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={3}
              mr={3}
              onClick={closeDocumentModal}
            />

            <Box p={4} mb={3}>
              <Heading.h3>Document is authentic!<DoneAll color="green"></DoneAll></Heading.h3>
              <div>
              </div>
              <Text>Authenticate Another NFD</Text>
            </Box>

            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button className="home" onClick={closeDocumentModal}><a>Home</a></Button>
            </Flex>
          </Card>
        </Modal> */}
    </div>
    
    );
}

export default AuthenticatePage;