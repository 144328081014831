import { Button } from '@mui/material';
import React, {useState, useEffect} from 'react';
import '../index.css'
import page1 from '../slides/NFD_Minting_Guide1024_1.png'
import page2 from '../slides/NFD_Minting_Guide1024_2.png'
import page3 from '../slides/NFD_Minting_Guide1024_3.png'
import page4 from '../slides/NFD_Minting_Guide1024_4.png'
import page5 from '../slides/NFD_Minting_Guide1024_5.png'
import page6 from '../slides/NFD_Minting_Guide1024_6.png'
import page7 from '../slides/NFD_Minting_Guide1024_7.png'
import page8 from '../slides/NFD_Minting_Guide1024_8.png'
import page9 from '../slides/NFD_Minting_Guide1024_9.png'
import page10 from '../slides/NFD_Minting_Guide1024_10.png'
import page11 from '../slides/NFD_Minting_Guide1024_11.png'
import page12 from '../slides/NFD_Minting_Guide1024_12.png'


export function HelpPage({images=[page1, page2, page3,
     page4, page5, page6, page7, page8, page9
     , page10, page11, page12], interval=3000}){
    const [thumbnails, setThumnails] = useState([]);
    const [previousSlideStyle, setPreviousSlideStyle] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [nextSlideStyle, setNextSlideStyle] = useState({});
    const [currentSlideStyle, setCurrentSlideStyle] = useState({});

    useEffect(()=>{
        setThumnails(images);
        setCurrentSlideStyle({
            backgroundImage: "url('"+images[currentSlide]+"')"
        });

        if(currentSlide>0){
            setPreviousSlideStyle({
                backgroundImage: "url('"+images[currentSlide-1]+"')"
            });
        }else{
            setPreviousSlideStyle({
                backgroundImage: "url('"+images[images.length-1]+"')"
            });
        }

        if(currentSlide === images.length-1){
            setNextSlideStyle({
                backgroundImage: "url('"+images[0]+"')"
            });
        }else{
            setNextSlideStyle({
                backgroundImage: "url('"+images[currentSlide+1]+"')"
            });
        } 

        const loop = setInterval(()=>{
            if(currentSlide === images.length-1){
                setCurrentSlide(0);
            }else{
                setCurrentSlide(currentSlide+1);
            }
        }, interval);
        return () => clearInterval(loop); 
    }, [images, currentSlide, interval]);

    function previous(){
        if(currentSlide>0){
            setCurrentSlide(currentSlide-1);
        }else{
            setCurrentSlide(thumbnails.length-1);
        }
    }

    function next(){
        if(currentSlide === thumbnails.length-1){
            setCurrentSlide(0);
        }else{
            setCurrentSlide(currentSlide+1);
        }
    }

    return (
        <section className="slideshow">
            <div className="slide-holder">
                <section className="slide previous-slide">
                    <div style={previousSlideStyle} className="slide-thumbnail"></div>
                </section>
                <section className="slide current-slide">
                    <div style={currentSlideStyle} className="slide-thumbnail"></div>
                </section>
                <section className="slide next-slide">
                    <div style={nextSlideStyle} className="slide-thumbnail"></div>
                </section>
            </div>

            <div className="slideshow-controller">
                <Button onClick={previous}>Previous</Button>
                <Button onClick={next}>Next</Button>
            </div>
        </section>
    )
}

export default HelpPage