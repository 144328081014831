import algosdk, { waitForConfirmation } from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import * as crypto from "crypto";
// import * as fs from 'fs'

export async function createASA(
  walletAddress: string,
  newUnitName: string,
  newAssetName: string,
  fileUrl: string,
  description: string,
  documentPage: string,
  documentWordCount: string,
  documentLocale: string,
  previewFileUrl: string,
  fileBuffer: Buffer,
  previewFileType: string,
  author: string,
  authorEmail: string,
  fileCreationDate: string,
  customFields: [{ key: string; value: string }] | [],
  nftStandard: "arc3" | "arc69"
) {
  try {
    if (typeof process.env.REACT_APP_ALGODTOKEN === "undefined" || process.env.REACT_APP_ALGODCLIENT === undefined) {
      throw "purestake apikey undefined error";
    }

    const baseServer = process.env.REACT_APP_ALGODCLIENT;
    const port = "";
    const token = {
      "X-API-Key": '',
    };

    const algodClient = new algosdk.Algodv2(token, baseServer, port);
    const params = await algodClient.getTransactionParams().do();

    // Whether user accounts will need to be unfrozen before transacting
    const defaultFrozen = false;
    // Used to display asset units to user
    const unitName = newUnitName; //SIGN, BTC
    // Friendly name of the asset
    const assetName = newAssetName; //SIGN.NET, Bitcoin
    // Optional string pointing to a URL relating to the asset
    const url =
      fileUrl !== "" ? "https://gateway.pinata.cloud/ipfs/" + fileUrl : "";

    // Optional hash commitment of some sort relating to the asset. 32 character length.
    // metadata can define the unitName and assetName as well.
    // see ASA metadata conventions here: https://github.com/algorandfoundation/ARCs/blob/main/ARCs/arc-0003.md
    const previewUrl =
      previewFileUrl !== ""
        ? "https://gateway.pinata.cloud/ipfs/" + previewFileUrl
        : "";
    const previewUrlData =
      previewFileUrl !== "" ? "ipfs://" + previewFileUrl : "";

    const previewFileDataType = previewFileType;
    const creator = author;
    const creatorEmail = authorEmail;
    const creationDate = fileCreationDate;
    // The following parameters are the only ones
    // that can be changed, and they have to be changed
    // by the current manager
    // Specified address can change reserve, freeze, clawback, and manager
    // If they are set to undefined at creation time, you will not be able to modify these later

    //manager account can destroy asset
    const managerAddr = walletAddress; // OPTIONAL: FOR DEMO ONLY, USED TO DESTROY ASSET WITHIN
    //const managerAddr = undefined;
    // Specified address is considered the asset reserve
    // (it has no special privileges, this is only informational)
    const reserveAddr = undefined;
    // Specified address can freeze or unfreeze user asset holdings
    const freezeAddr = undefined;
    // Specified address can revoke user asset holdings and send
    // them to other addresses
    const clawbackAddr = undefined;

    const decimals = 0;
    const total = 1; // how many of this asset there will be

    const encoder = new TextEncoder(); // always utf-8

    //for compatability
    const notes = {
      creator: walletAddress,
      description: description,
      external_link: previewUrlData,
      mime_type: previewFileDataType,
      document_link: url,
      properties: {
        pages: documentPage === "" ? "Nil" : documentPage,
        wordCount: documentWordCount === "" ? "Nil" : documentWordCount,
        language: documentLocale === "" ? "Nil" : documentLocale,
        creation_date: fileCreationDate === "" ? "Nil" : fileCreationDate,
      },
      sensitive_content: false,
      standard: nftStandard,
    };
    const userCustomFields2 = customFields;

    const updatedCustomInput = userCustomFields2.map((data) => {
      const keyData = data.key;
      //create new key value
      const newFieldObject = { [keyData]: data.value };
      //add new key values to properties object
      const updatedMetadata = Object.assign(notes.properties, newFieldObject);

      //add new properties object to existing preset metadata
      const newFieldObject2 = { properties: updatedMetadata };

      const customMetadata = Object.assign(notes, newFieldObject2);

      return customMetadata;
    });

    const createNFDNote =
      customFields.length === 0
        ? encoder.encode(JSON.stringify(notes))
        : encoder.encode(JSON.stringify(updatedCustomInput[0]));

    //form document integrity data from file buffer
    const fileDataBuffer = fileBuffer;
    const hashDocument = crypto.createHash("sha256");
    hashDocument.update(fileDataBuffer);
    const hashDocumentBase64 = hashDocument.digest("base64");
    console.log(hashDocumentBase64);
    const documentIntegrity = "sha256-" + hashDocumentBase64;

    const userCustomFields = customFields;
    // console.log('-------initial----------')
    // console.log(userCustomFields)
    // console.log('-----------------')

    const nftARC = nftStandard;
    // console.log('-------ARC Standard----------')
    // console.log(nftARC)
    // console.log('-----------------')

    // //ARC3 vs ARC69
    // //(properties fields are all optional data users wants plus our own preset optional data)
    // //ARC3 metadata format
    // const arc3 = {
    //     decimals: decimals,
    //     document_url: url,
    //     document_integrity: documentIntegrity,
    //     document_mimetype: "application/pdf",   //document
    //     name: assetName,
    //     properties: {
    //         creator: creator,
    //         creator_email: creatorEmail,
    //         creation_date: creationDate,
    //         description: description,
    //         document_words: documentWordCount,
    //         document_pages: documentPage,
    //         document_locale: documentLocale,
    //         image_mimetype: previewFileDataType, //image type .png, .jpeg, ,jpg
    //     },
    //     unitName: unitName,
    // }

    // //ARC69 netadata format
    // const ARC69 = {
    //         standard: "arc69",
    //         description: description,
    //         mime_type: "application/pdf",
    //         external_url: "",
    //         properties: {
    //                 unitName: unitName,
    //                 assetName: assetName,
    //                 document_url: url,
    //                 document_integrity: documentIntegrity,
    //                 document_words: documentWordCount,
    //                 document_pages: documentPage,
    //                 document_locale: documentLocale,
    //                 preview_url: previewUrl, //image ipfs url
    //                 image_mimetype: previewFileDataType, //image type .png, .jpeg, ,jpg
    //                 creator: creator,
    //                 creator_email: creatorEmail,
    //                 creation_date: creationDate,

    //         }
    // }

    const intialMetadataObj =
      nftARC === "arc3"
        ? {
            decimals: decimals,
            document_url: url,
            document_integrity: documentIntegrity,
            document_mimetype: "application/pdf", //document
            name: assetName,
            properties: {
              creator: creator,
              creator_email: creatorEmail,
              creation_date: creationDate,
              description: description,
              document_words: documentWordCount,
              document_pages: documentPage,
              document_locale: documentLocale,
              image_mimetype: previewFileDataType, //image type .png, .jpeg, ,jpg
            },
            unitName: unitName,
          }
        : //ARC69
          {
            standard: "arc69",
            description: description,
            mime_type: "application/pdf",
            external_url: url,
            document_integrity: documentIntegrity,
            properties: {
              unitName: unitName,
              assetName: assetName,
              document_url: url,
              document_words: documentWordCount,
              document_pages: documentPage,
              document_locale: documentLocale,
              preview_url: previewUrl, //image ipfs url
              image_mimetype: previewFileDataType, //image type .png, .jpeg, ,jpg
              creator: creator,
              creator_email: creatorEmail,
              creation_date: creationDate,
            },
          };

    const updatedCustomMetadata = userCustomFields.map((data) => {
      const keyData = data.key;
      //create new key value
      const newFieldObject = { [keyData]: data.value };
      //add new key values to properties object
      const updatedMetadata = Object.assign(
        intialMetadataObj.properties,
        newFieldObject
      );

      //add new properties object to existing preset metadata
      const newFieldObject2 = { properties: updatedMetadata };
      const editedMetadataObj =
        nftARC === "arc3"
          ? {
              decimals: decimals,
              document_url: url,
              document_integrity: documentIntegrity,
              document_mimetype: "application/pdf", //document
              name: assetName,
              unitName: unitName,
            }
          : {
              standard: "arc69",
              description: description,
              mime_type: "application/pdf",
              external_url: "",
              document_integrity: documentIntegrity,
            };

      const customMetadata = Object.assign(editedMetadataObj, newFieldObject2);

      return customMetadata;
    });
    // console.log('--------NFT based---------')
    // console.log(updatedCustomMetadata[0])
    // console.log('-----------------')

    const metadataObj =
      customFields.length === 0 ? intialMetadataObj : updatedCustomMetadata[0];

    console.log(metadataObj);

    const metadataBuffer = Buffer.from(JSON.stringify(metadataObj));
    const hash = crypto.createHash("sha256");
    hash.update(metadataBuffer);
    const metadata = new Uint8Array(hash.digest());
    //algorand hash
    console.log(Buffer.from(metadata).toString("base64"));

    //-------------1st transaction for creation of Asset----------------------//
    // signing and sending "txn" allows "addr" to create an asset
    const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
      from: walletAddress,
      total,
      decimals,
      assetName,
      unitName,
      assetURL: previewUrlData,
      assetMetadataHash: metadata,
      defaultFrozen,
      freeze: freezeAddr,
      manager: managerAddr,
      clawback: clawbackAddr,
      reserve: reserveAddr,
      note: createNFDNote,
      suggestedParams: params,
    });

    //----------2nd transaction for payment of sign ASA to hot wallet-------------//
    // asset id: 66031611 Test ASA
    let params2 = await algodClient.getTransactionParams().do();
    //comment out the next two lines to use suggested fee
    params2.fee = 1000;
    params2.flatFee = true;

    const sender = walletAddress;
    const recipient =
      "FS3O5XZLGB7SJGH6YNGVKSUPNT255QXXCEQMLP3QEAQILACKPGG7MIQGZI"; //test hot wallet
    const revocationTarget = undefined;
    const closeRemainderTo = undefined;
    //Amount of the asset to transfer
    //const amount = process.env.NODE_ENV === 'development' ? 500 : 50000000// equals to 5 TEST ASA (2.d.p) Mainnet SIGN(7 d.p.)
    if (typeof process.env.REACT_APP_PAYMENT_AMOUNT === "undefined") {
      throw "asset amount undefined error";
    }
    const amount = parseInt(process.env.REACT_APP_PAYMENT_AMOUNT);
    const enc = new TextEncoder(); // always utf-8
    const note = enc.encode("Payment of SIGN ASA for minting NFD");

    // const envVariable = process.env.REACT_APP_ASSETID as string
    // const assetIdASA = parseInt(envVariable)
    const assetASAId =
      process.env.NODE_ENV === "development" ? 66031611 : 266846137;

    // signing and sending "txn" will send "amount" assets from "sender" to "recipient"
    let txn2 = algosdk.makeAssetTransferTxnWithSuggestedParams(
      sender,
      recipient,
      closeRemainderTo,
      revocationTarget,
      amount,
      note,
      assetASAId,
      params2
    );

    const txnsToGroup = [txn, txn2];
    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < 2; i++) txnsToGroup[i].group = groupID;

    const myAlgoConnect = new MyAlgoConnect();
    const signedTxns = await myAlgoConnect.signTransaction(
      txnsToGroup.map((txn) => txn.toByte())
    );
    const txID1 = signedTxns[0].txID;
    const txID2 = signedTxns[1].txID;
    console.log([txID1, txID2]);

    //broadcast transactions
    let signed = [];
    signed.push(signedTxns[0].blob);
    signed.push(signedTxns[1].blob);
    let tx = await algodClient.sendRawTransaction(signed).do();
    await waitForConfirmation(algodClient, tx.txId, 4);
    const ptx = await algodClient.pendingTransactionInformation(tx.txId).do();
    const assetID = ptx["asset-index"];
    console.log(assetID);
    //return {assetID, txID1, txID2}
    return {
      result: "Success",
      assetID: assetID,
      transactionIDs: [txID1, txID2],
      metadata: metadataObj,
    };
  } catch (err) {
    console.log("err", err);
    return {
      result: "Fail",
      assetID: null,
      errMsg: err,
    };
  }
}

export async function optInToAsset(walletAddress: string, assetId: number) {
  try {
    ///optIn to asset
    if (typeof process.env.REACT_APP_ALGODTOKEN === "undefined" || process.env.REACT_APP_ALGODCLIENT === undefined) {
      throw "purestake apikey undefined error";
    }

    const baseServer = process.env.REACT_APP_ALGODCLIENT;
    const port = "";
    const token = {
      "X-API-Key": '',
    };

    const algodClient = new algosdk.Algodv2(token, baseServer, port);
    const params = await algodClient.getTransactionParams().do();
    //comment out the next two lines to use suggested fee
    params.fee = 1000;
    params.flatFee = true;

    let sender = walletAddress;
    let recipient = sender;
    // We set revocationTarget to undefined as
    // This is not a clawback operation
    let revocationTarget = undefined;
    // CloseReaminerTo is set to undefined as
    // we are not closing out an asset
    let closeRemainderTo = undefined;
    // We are sending 0 assets
    let amount = 0;
    let note = undefined;
    // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
    let opttxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
      sender,
      recipient,
      closeRemainderTo,
      revocationTarget,
      amount,
      note,
      assetId,
      params
    );

    //sign with myAlgo UI
    const myAlgoConnect = new MyAlgoConnect();
    const signedTxn = await myAlgoConnect.signTransaction(opttxn.toByte());

    //Broadcast transaction
    let tx = await algodClient.sendRawTransaction(signedTxn.blob).do();
    const confirmedTxn = await waitForConfirmation(algodClient, tx.txId, 4);
    console.log(confirmedTxn);
    return confirmedTxn;
  } catch (err) {
    console.log("err", err);
    return {
      result: "Fail",
      errMsg: err,
    };
  }
}
