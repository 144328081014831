import Mint from './pages/Mint'
import Confirmation from './pages/Confirmation'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react"
import { Pipeline } from "pipeline-ui"
import Authenticate from './pages/Authenticate';
import Help from './pages/Help';
import HideAppBar from './components/NavBar';
import About from './pages/About';
import WalletInfo from './pages/WalletInfo'

function App() {
  const [unitName, setUnitName] = useState("");
  const [assetName, setAssetName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("")

  const [address, setAddress] = useState("");
  const [main, setMain] = useState(true);
  const [file, setFile] = useState("");
  const [PreviewFile, setPreviewFile] = useState("");
  const [note, setNote] = useState("")
  const [description, setDescription] = useState("")
  const [documentPage, setDocumentPage] = useState("")
  const [documentWordCount, setDocumentWordCount] = useState("")
  const [documentLocale, setDocumentLocale] = useState("")

  const [creator, setCreator] = useState("")
  const [creatorEmail, setCreatorEmail] = useState("")
  const [creationDate, setCreationDate] = useState("")

  const [formValues, setFormValues] = useState([{ key: "", value : ""}])

const handleAddressName = e => {
    setAddress(e);
  };


  const handleUnitName = e => {
    setUnitName(e.target.value);
  };

  const handleAssetName = e => {
    setAssetName(e.target.value);
  };

  const handlePersonalEmail = e => {
    setPersonalEmail(e.target.value)
  }

  const handleDescription = e => {
    setDescription(e.target.value);
  };

  const handleNote = e => {
    setNote(e.target.value);
  };


  const handleDocumentPage= e => {
    setDocumentPage(e.target.value);
  };

  const handleDocumentWordCount = e => {
    setDocumentWordCount(e.target.value);
  };

  const handleLocale = e => {
    setDocumentLocale(e.target.value);
  };


  const handleCreator = e => {
    setCreator(e.target.value);
  };

  const handleCreatorEmail = e => {
    setCreatorEmail(e.target.value);
  };

  const handleCreationDate = e => {
    setCreationDate(e.target.value);
  };

  const handleFile = e => {
      setFile(e.target.files[0])
  }

  const myAlgoWallet = Pipeline.init();
  
  const handleMain = () => {
    // check if main
    if (
      main == true
    ) {
      setMain(false);
    } else {
      setMain(true);
    }
  };
    return(
      <div>
     
        <Router>
        <HideAppBar></HideAppBar>
        <Routes>
            {/* ------------------home route(Mint)-------------------- */}
            <Route exact path="/mint" element={<Mint 
            unitName={unitName} handleUnitName={handleUnitName} 
            assetName={assetName} handleAssetName={handleAssetName}
            personalEmail={personalEmail} handlePersonalEmail={handlePersonalEmail}
            main={main} handleMain={handleMain} 
            file={file} setFile={setFile} handleFile={handleFile}
            description={description} handleDescription={handleDescription}  
            note={note} handleNote={handleNote}
            documentPage={documentPage} handleDocumentPage={handleDocumentPage} 
            documentWordCount={documentWordCount} handleDocumentWordCount={handleDocumentWordCount}
            documentLocale={documentLocale} handleLocale={handleLocale}
            PreviewFile={PreviewFile} setPreviewFile={setPreviewFile}
            creator={creator} handleCreator={handleCreator}
            creatorEmail={creatorEmail} handleCreatorEmail={handleCreatorEmail}
            creationDate={creationDate} handleCreationDate={handleCreationDate}
            formValues={formValues} setFormValues={setFormValues}
            />} />
            {/* ------------------confirmation route----------------- */}
            <Route path="/confirmation" element={<Confirmation 
            unitName={unitName} assetName={assetName} 
            personalEmail={personalEmail} handlePersonalEmail={handlePersonalEmail}
            main={main}
            address={address} handleAddressName={handleAddressName}
            myAlgoWallet={myAlgoWallet} 
            file={file} handleFile={handleFile} 
            description={description} handleDescription={handleDescription} 
            note={note} handleNote={handleNote}
            documentPage={documentPage} handleDocumentPage={handleDocumentPage} 
            documentWordCount={documentWordCount} handleDocumentWordCount={handleDocumentWordCount}
            documentLocale={documentLocale} handleLocale={handleLocale} 
            PreviewFile={PreviewFile} setPreviewFile={setPreviewFile}
            creator={creator} handleCreator={handleCreator}
            creatorEmail={creatorEmail} handleCreatorEmail={handleCreatorEmail}
            creationDate={creationDate} handleCreationDate={handleCreationDate}
            formValues={formValues} setFormValues={setFormValues}
            />} />
            {/* ------------------authentication route----------------- */}
            <Route path="/authenticate" element={<Authenticate />} />
            {/* -----------------------Help route---------------------- */}
            <Route path="/help" element={<Help />} />
            {/* -----------------------About route---------------------- */}
            <Route path="/about" element={<About />} />
            {/* -----------------------wallet Info route---------------------- */}
            <Route path="/" element={<WalletInfo />} />
        </Routes>
      </Router>
      </div>
    );
}

export default App;

