import React, {useState} from 'react'
import { useNavigate, } from "react-router-dom"
import { CloudDone } from '@pipeline-ui/icons'
import StyledDropzone from '../components/DropDragFile'
import PreviewDrop from '../components/PreviewDrop'

import {
    Button
  } from 'pipeline-ui'
import '../index.css'
import ReactTooltip from 'react-tooltip';
import logo from '../signnet.png'

export function Mint(props) {

  const navigate = useNavigate()
  const [error, setError] = useState("");
  const errorMsg = error;

  const [OptionalFieldError, setoptionalFieldError] = useState("");
  const numberRegex = new RegExp("^[0-9]+$");
  const optionalFieldsErrorMsg = OptionalFieldError;

  const emailRegex = new RegExp('\\S+@\\S+\\.\\S+')

   //const [formValues, setFormValues] = useState([{ key: "", value : ""}])

	
  const handleChange = (i, e) => {
    let newFormValues = [...props.formValues];
    newFormValues[i][e.target.name] = e.target.value;
    props.setFormValues(newFormValues);
  }

  const addFormFields = () => {
    setoptionalFieldError('')
    const isAnyEmpty = props.formValues.some((data) => data.key === '' || data.value === '')
    if(!isAnyEmpty){
      props.setFormValues([...props.formValues, { key: "", value: "" }])
    } else {
      setoptionalFieldError('Key-value pairs cannot be empty.')
    }
  }

  const removeFormFields = (i) => {
      let newFormValues = [...props.formValues];
      newFormValues.splice(i, 1);
      props.setFormValues(newFormValues)
  }

  const isOneOfTheFirstFieldEmpty = (props.formValues[0].key === '' && props.formValues[0].value !== '') || (props.formValues[0].key !== '' && props.formValues[0].value === '')

  const validateCustomField = (props.formValues).length === 1 && isOneOfTheFirstFieldEmpty

  function handleClick() {
    setError("")
    setoptionalFieldError("")
    if((props.unitName.length > 8)){
        setError("Unit name cannot exceed 8 characters.");
    }else if( props.assetName.length > 32 ){
        setError("Asset name cannot exceed 32 characters.");
    }else if( props.unitName.length === 0){
      setError("Unit name cannot be empty.");
    }else if( props.assetName.length === 0){
      setError("Asset name cannot be empty.")
    // }else if( props.personalEmail.length === 0){
    //   setError("Email cannot be empty.")
    // }else if( emailRegex.test(props.personalEmail) === false) {
    //   setError("Wrong email format. e.g. john@example.net");
    }else if( props.file === ""){
      setError("Please upload a pdf file before proceeding.");
    }else if( props.PreviewFile === ""){
      setError("Please upload a preview image(.img, .png, .jpeg or .jpg) file before proceeding.");
    }else if( numberRegex.test(props.documentPage) === false && props.documentPage.length > 0){
      setoptionalFieldError("Please enter numbers only for document page and count.");
    }else if( numberRegex.test(props.documentWordCount) === false && props.documentWordCount.length > 0){
      setoptionalFieldError("Please enter numbers only for document page and count.");
    }else if( emailRegex.test(props.creatorEmail) === false && props.creatorEmail.length > 0) {
      setoptionalFieldError("Wrong email format. e.g. john@example.net");
    }else if(validateCustomField) {
      setoptionalFieldError("One of your custom fields is empty. Please fill in it or leave both key-values empty.");
    } else {
      navigate("/confirmation", {replace: true});
    }
  }
  function handleAuthenticateClick() {navigate("/authenticate", {replace: true});
  }

    return (
<div align="center" className="card">    
  <img alt="Create Non-Fungible Document" src={logo} width="300"></img><br></br>
    <div className="toggle-select">
  </div> 
    <h4>Create a Non-Fungible Document</h4>  
      <form >
        <label className="form-label">
          Unit Name :
          <input type="text" placeholder="e.g. ALICE (max 8 characters)" className="form-control" value={props.unitName} onChange={props.handleUnitName} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="This is the unique name of the token that will be used to govern your NFD once created. It should be short and memorable, and is how we refer to it as single unit using a maximum of 8 characters."></a>
          <ReactTooltip place="top" type="dark" effect="solid"/>
        </label><br></br>
        <label class="form-label">
          Asset name:
          <input type="text"  placeholder="e.g. ALICE MANUSCRIPT 001" className="form-control" value={props.assetName} onChange={props.handleAssetName} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="This is how we want to describe the NFD which you are creating. It can be part of a series for future reference, or a unique numerical tag to differentiate various single units. Maximum of 32 characters."></a>
          <ReactTooltip place="top" type="dark" effect="solid"/>
        </label>
        {/* <label class="form-label">
          Your Email:
          <input type="text"  placeholder="Enter your email" className="form-control" value={props.personalEmail} onChange={props.handlePersonalEmail} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="An email will be sent after minting of NFD is completed."></a>
          <ReactTooltip place="top" type="dark" effect="solid"/>
        </label> */}
      </form>
      
        <div><StyledDropzone setFile={props.setFile} file={props.file} /></div>
        {/* <div><PDF></PDF></div> */}

        <h4>Optional Metadata of NFD</h4>
        <form >
        <label className="form-label">
          Description:
          <input type="text" placeholder="Alice's first book manuscript" className="form-control" value={props.description} onChange={props.handleDescription} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Used to provide short description of your NFD. Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <label className="form-label">
          Pages:
          <input type="text" placeholder="Total number of pages in the document" className="form-control" value={props.documentPage} onChange={props.handleDocumentPage} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip=" Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <label className="form-label">
          Word Count:
          <input type="text" placeholder="Total word count of the document" className="form-control" value={props.documentWordCount} onChange={props.handleDocumentWordCount} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Provide word count of your document. Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <label className="form-label">
          Language:
          <input type="text" placeholder="Language of the document, e.g. en" className="form-control" value={props.documentLocale} onChange={props.handleLocale} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Provide language of your document. Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <label className="form-label">
          Creator:
          <input type="text" placeholder="Author of document" className="form-control" value={props.creator} onChange={props.handleCreator} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Provide Author of your document. Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <label className="form-label">
          Email:
          <input type="text" placeholder="Email of author" className="form-control" value={props.creatorEmail} onChange={props.handleCreatorEmail} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Provide email of author. Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <label className="form-label">
          Creation Date:
          <input type="date" placeholder="Date of publish" className="form-control" value={props.creationDate} onChange={props.handleCreationDate} />
          <a className="toolTip" style = {{paddingLeft:"10px"}} data-tip="Provide date of publish. Does not impact functionality."></a>
          <ReactTooltip id="tooltip" place="top" type="dark" effect="solid"/>
        </label>
        <h5>Add your custom fields</h5>
        {props.formValues.map((element, index) => (
          <div style={{ display: 'flex', marginLeft: '5px'}}>
            <label className="form-label" key={index}>
              <div style={{ display: 'flex', width: '365px'}}>
              <input type="text" placeholder="Key" className="form-control" name="key" value={element.key || ""} onChange={e => handleChange(index, e)} />
              <input type="text" placeholder="Value" className="form-control"  name="value" value={element.value || ""} onChange={e => handleChange(index, e)} />
              </div>
              {
                index ? 
                  <div style={{ display: 'flex'}}><button type="button"  className="button remove" onClick={() => removeFormFields(index)}>Remove</button> </div>
                : null
              }
            </label>
            </div>
          ))}
          <div className="button-section">
              <button className="button add" type="button" onClick={() => addFormFields()}>Add more fields</button>
          </div>

        <h5 style = {{color: "red"}}>{"" + optionalFieldsErrorMsg}</h5>
        <PreviewDrop setFile={props.setPreviewFile} PreviewFile={props.PreviewFile}>
        </PreviewDrop>
      </form>
      <h5 style = {{color: "red"}}>{"" + errorMsg}</h5>
        
        <Button onClick={handleClick} className="confirm-button" ><CloudDone></CloudDone><a>Proceed</a></Button>


      
</div>
    )
}

export default Mint;
