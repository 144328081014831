import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

const useStyles = makeStyles({
    buttonColor: {
        color: "#45454f",
      "&.Mui-selected": {
        backgroundColor: "#123456"
      },
      "&.Mui-hover": {
          color: "black"
      }
    }
  });

  export default function ToggleNFTButtons(props) {
    console.log(props.nftStandard)
    const classes = useStyles();

    const customTheme = createTheme({
        palette: {
          primary: {
            main: '#BBBBBB',
            contrastText: "#000000 ",
          },
          secondary: {
            main: '#FFFFFF',

          },
 
        },
       
      });

  return (
      <ThemeProvider theme={customTheme}>
    <ToggleButtonGroup sx={{
          height: '35px',
          bgcolor: customTheme.palette.primary.main,
          
        }}
    size='small'
      color='secondary'
      value={props.nftStandard}
      exclusive
      onChange={props.handleNFTStandard}
      aria-label="Storage Option"
    >
      <ToggleButton size='small' className={classes.buttonColor} value={"arc69"} aria-label="ARC69">
        <a>ARC69</a>
      </ToggleButton>
      <ToggleButton size='small' className={classes.buttonColor} value={"arc3"} aria-label="ARC3">
      <a>ARC3</a>
      </ToggleButton>
    </ToggleButtonGroup>
    </ThemeProvider>
  );
}