import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

const useStyles = makeStyles({
    buttonColor: {
        color: "#45454f",
      "&.Mui-selected": {
        backgroundColor: "#123456"
      },
      "&.Mui-hover": {
          color: "black"
      }
    }
  });

export default function ToggleButtons(props) {
    console.log(props.Private)
    const classes = useStyles();

    const customTheme = createTheme({
        palette: {
          primary: {
            main: '#BBBBBB',
            contrastText: "#000000 ",
          },
          secondary: {
            main: '#FFFFFF',

          },
 
        },
       
      });

  return (
      <ThemeProvider theme={customTheme}>
    <ToggleButtonGroup sx={{
          height: '35px',
          bgcolor: customTheme.palette.primary.main,
          
        }}
    size='small'
      color='secondary'
      value={props.Private}
      exclusive
      onChange={props.handlePrivate}
      aria-label="Storage Option"
    >
      <ToggleButton size='small' className={classes.buttonColor} value={false} aria-label="IPFS">
        <a>Store on IPFS</a>
      </ToggleButton>
      <ToggleButton size='small' className={classes.buttonColor} value={true} aria-label="Private">
      <a>Store Privately</a>
      </ToggleButton>
    </ToggleButtonGroup>
    </ThemeProvider>
  );
}