import React from "react"
import {Button} from "pipeline-ui";
import * as crypto from "crypto";

class write extends React.Component {
    constructor(props) {
      super(props)
      this.fileNames = {
          json: "metadata.json",
      }    
      this.state = {
        fileType: "json",
        fileDownloadUrl: null,
        status: "",
        data: this.props.metadata,
        
      }
        this.download = this.download.bind(this);
    }
    componentDidUpdate(prevProps) {
      if(prevProps.metadata !== this.props.metadata) {
        this.setState(this.state.data);
        const output =JSON.stringify(this.state.data);
          console.log(output)
          const blob = new Blob([output]);
          const file = new File([blob], "metadata.json", {lastModified: new Date(), fileType: "application/json"});
          console.log(file);
          
          
          this.props.setMetadataFile(file);
      }
    }
    
    download (event) {
      event.preventDefault();
        // Prepare the file
      let output;
      if (this.state.fileType === "json") {
          output = JSON.stringify(this.state.data);
      }
      // Download it
      console.log(output)
    // const metadataBuffer = Buffer.from(output)
    // console.log(metadataBuffer)
    // const hash = crypto.createHash('sha256');
    // hash.update(metadataBuffer);
    // const metadata = new Uint8Array(hash.digest());
    // console.log(Buffer.from(metadata).toString('base64'))
    // console.log(metadata)
      const blob = new Blob([output]);
      console.log(blob)
      const fileDownloadUrl = URL.createObjectURL(blob);
      this.setState ({fileDownloadUrl: fileDownloadUrl}, 
        () => {
          this.dofileDownload.click(); 
          URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
          this.setState({fileDownloadUrl: ""})
      });    
    }

    /**
     * Function returns the content as a CSV string
     * See https://stackoverflow.com/a/20623188/64904
     * Parameter content:
     *   [
     *.     [header1, header2],
     *.     [data1, data2]
     *.     ...
     *.  ]
     * NB Does not support Date items
     */
    
    
    /**
     * Process the file within the React app. We're NOT uploading it to the server!
     */
    
    render() {
      return (
        <div>
          <form>
            <h3 className="mr">Download metadata.json file for authentication of your NFD by clicking on button below: </h3>
            <Button onClick={this.download}>Download 'metadata.json'</Button>
            
            <a className="hidden"
               download={this.fileNames[this.state.fileType]}
               href={this.state.fileDownloadUrl}
               ref={e=>this.dofileDownload = e}
            ></a>
            
          </form>
          <pre className="status">{this.state.status}</pre>
        </div>
        )
    }
  }
  
  export default write
  